@import "../../styles/shared.scss";


.infoBox{
    position: fixed;
    z-index: 161535741;
    left:var(--page-gutter);
    top: var(--page-gutter);
    border-radius: 20px;
    background-color: var(--brand-color);
    width: 320px;
    padding: 35px 40px;
    color:var(--text-color);
    visibility: hidden;
    opacity: 0;
    text-align: left;
   
    @include max-responsive(sm){
        background-color: var(--brand-color);
        backdrop-filter: blur(1px);
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 24px;
        padding-bottom: 14px;
        width: 96%;
        pointer-events: none;
        z-index: 5000000000;
    }

    p{
        margin: 0;
    }

    > div{
        display: none;
        margin-top: 30px;
        color:var(--text-color);

        svg{
            width: 40px;
        }

        @include max-responsive(sm){
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;

        }
    }
  
}
.title{
    font-family: $font-montserrat;
    font-weight: 900;
    line-height: 1.1;
    font-size: 20px;
    margin-bottom: 10px !important;
}
.text{
    font-family: $font-montserrat;
    font-weight: 700;
    font-size: 13px;
    line-height: 1.2;
    margin-bottom: 15px !important;
}
