@import '../../styles/shared';

.soundPlayer{
    position: fixed;
    top: calc(var(--page-gutter) + 2px);
    right: 125px;
    z-index: 4;
    color: var(--brand-color);
    background: rgba(31, 47, 128, 0.4);
    padding: 0;
    border: none;
    transition: transform 0.5s;
    border: 2px solid var(--brand-color);
    color: var(--brand-color);
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
        align-items: center;
        justify-content: center;

        svg{
            width: 22px;
        }    
    &:focus{
        transform: scale(1.05);
    }
    &:hover{
        transform: scale(1.2);

        

    }
    @include max-responsive(sm){
        color: white;
        width: 35px;
        height: 35px;
        border: 2px solid var(--brand-color);
        color: var(--brand-color);
        background: rgba(31, 47, 128, 0.3);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 61px;


        svg{
            width: 18px;
        }
    }

}

.soundPlayerStart{
    position: fixed;
    top: var(--page-gutter);
    right: 65px;
    z-index: 161535741;
    color: var(--brand-color);
    background: rgba(31, 47, 128, 0.4);
    padding: 0;
    border: none;
    transition: transform 0.5s;
    border: 2px solid var(--brand-color);
    color: var(--brand-color);
    border-radius: 100px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
        justify-content: center;

        svg{
            width: 24px;
        }    
    &:focus{
        transform: scale(1.05);
    }
    &:hover{
        transform: scale(1.2);

        

    }
    @include max-responsive(sm){
        color: white;
        width: 35px;
        height: 35px;
        border: 2px solid var(--brand-color);
        color: var(--brand-color);
        background: rgba(31, 47, 128, 0.3);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        right: 15px;


        svg{
            width: 18px;
        }
    }

}