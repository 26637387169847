@import "../styles/shared.scss";

.status{
    position: fixed;
    z-index: 161535741;
    bottom: 0;
    right: 0;
   
}


.jauge {
    z-index: 20;
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 20px;
    margin-right: 20px;

    @include max-responsive(sm){
        margin-right: 10px;

    }


}

.iconStatusBar {
    position: relative;
 

img{
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    object-fit: cover;

    @include max-responsive(sm){
        visibility: hidden;
        position: fixed;
    top: calc(var(--page-gutter) + 2px);
    left: 62px;
    width: 35px;
    height: 35px;
    

    }
}
}

.statusBar{
    all:unset;
    right: 20px;
    bottom: 20px;
    width: 25px;
    height: 110px;
    border-radius: 20px;
    border: solid 2px var(--brand-color);
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap:2px;
    cursor: pointer;

    > div{
        width: 100%;
        height: 100%;
        background-color: var(--brand-color);
        transform: scaleY(0);
        transform-origin: bottom;
        transition: transform 1s $ease-out-expo;
    }

    @include max-responsive(sm){
        width: 15px;

    }
}
.found{
    transform: scaleY(1) !important;
}

.statusWrap{
    width: 364px;
    bottom: 135px;
    right: 20px;
    background-color: var(--brand-color);
    padding: 15px 30px 20px;
    border-radius: var(--border-radius);
    position: absolute;
    border: solid 0px var(--text-color);
    transform: translate(0,10px);
    pointer-events: none;
    transition: all 1s $ease-out-expo;
    opacity: 0;
    p{
        color:var(--text-color);
        font-family: $font-montserrat;
        font-size: 16px;

        strong{
            font-weight: 900;
        }
    }
    @include max-responsive(sm){
        width: calc(100vw - 40px);
    }

    img{
        width: 58px;
        height: 58px;
        border-radius: 100px;
        overflow: hidden;
        object-fit: cover;
        margin-top: 10px;
    }
}
.is-open{
    transform: none;
    opacity: 1;
}



.buttonMentions {
    position: fixed;
    z-index: 161535740;
    top: var(--page-gutter);
    right: calc(var(--page-gutter) - 10px);
    @include max-responsive(sm){
        top:  calc(var(--page-gutter));;
        right: calc(var(--page-gutter));
        
        svg {
            width: 35px;
        }
    }
}

.popupMentions {
    width: 364px;
    top: 110px;
    right: 20px;
    background-color: var(--brand-color);
    padding: 15px 30px 20px;
    border-radius: 20px;
    position: absolute;
    border: solid 0px var(--text-color);
    transform: translate(0,10px);
    z-index: 1000000000;

    a {
        font-size: 1Opx;
        display: block;
        line-height: 2.5em;
        color: var(--text-color);
    }

    p {
        cursor:pointer;
        color: var(--text-color);
        text-decoration: underline;

    }
}