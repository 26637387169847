@import '../../styles/shared.scss';


.scenarioButton{
    position:fixed;
    top: var(--page-gutter);
    left: calc(var(--page-gutter) - 7px);
    z-index: 3;
    transition: transform 1s $ease-out-expo;
    cursor: pointer;
    border: none;
    color: var(--brand-color);
    background: var(--text-color);
    border-radius: 100px;
    width: 50px;
    height: 50px;
    transition: transform 0.5s;

    svg{
        width: 25px;
    }

    &:focus{
        transform: scale(1.05);
    }

    @include hover{
        transform: scale(1.1);
    }

    @include max-responsive(sm){
        color: var(--brand-color);
        top: calc(var(--page-gutter) + 2px);
        left: var(--page-gutter);
        border: 2px solid var(--brand-color);
        color: var(--brand-color);
        background: rgba(31, 47, 128, 0.2);
        border-radius: 100px;
        width: 35px;
        height: 35px;
        svg{
            width: 18px;
            stroke-width: 2px;
            margin-left: -5px,
        }

    }

  

}

