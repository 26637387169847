@import "../styles/shared.scss";

.etiquette {
    background: #DCF9F5;
    border: 1px solid var(--text-color);
    padding: 5px;
    border-radius: 25px;
    font-family: $font-montserrat;
    width: 150px;
    padding: 10px;
    color: #0A2D82;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
    
    }

    .etiquette:focus {
     background: var(--brand-color); /* Assurez-vous que --brand-color est défini */
     border: 2px solid var(--text-color);
     outline: none;
 }



