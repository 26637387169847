@import "../../styles/shared.scss";

.popupInfo{
    visibility: hidden;
    opacity: 0;
    z-index: 161535745;
    position: fixed;
    top: 50%;
    right: var(--page-gutter);
    transform: translate(0,-50%);
    background-color: var(--brand-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 30px 32px 40px;
    width: 432px;
    max-width: 100%;
    color:var(--text-color);
    @include max-responsive(sm){
        background-color: var(--brand-color);
        top: inherit;
        bottom: 0;
        left: 0;
        right: inherit;
        width: 100%;
        transform: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        z-index: 5000000000;
    }
}

.popupIframe{
    width: 100%;
    padding-bottom: 56%;
    position: relative;
    iframe{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
    }
}

.popupInfoTitle{
    font-family: $font-montserrat;
    font-weight: 900;
    font-size: 24px;
    margin: 0;
    text-align: left;
    @include max-responsive(sm) {
        font-size:20px;
    }
}

.popupInfoText{
    font-family: $font-montserrat;
    font-size: 14px;
    text-align: left;
    margin-bottom: 25px;
    @include max-responsive(sm) {
        font-size:14px;
        max-height: 175px;
        scrollbar-width: auto;
        overflow: scroll;
        margin-bottom: 15px;
    }
}

.popupClose{
    all:unset;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    z-index: 3;
    cursor: pointer;
    transition: transform 1s $ease-out-expo;
    &:hover{
        transform: scale(1.1);
    }

    svg {

        width: 20px;
    height: 20px;
    }
}

.scenarioImage{
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: start;


    img{
        left: 0;
        top: 0;
        width: 70px;
        height: 70px;
        border-radius: 8px;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        

       
    }
    .scenarioHead {
        display: flex;
        flex-direction: column; /* Les éléments s'empilent verticalement */
        align-items: flex-start; /* Centre les éléments horizontalement */
        margin-left: 15px; /* ajustez cette valeur selon vos besoins */
        justify-content: center;
        height: 90px;
    
        p {
            font-size: 16px;
        }
        .questLabel {
            /* Style pour 'Quête' */
            font-family: $font-montserrat;
            font-size: 16px; /* Taille de la police */
            justify-content: center;
            margin-bottom: 6px;
            margin-top: 0px;

            @include max-responsive(sm) {
                font-size:14px;
                margin-bottom: 6px;
                margin-top: 0px;

            }
            
        }
    }
  
}


.scenarioCtas{
    display: flex;
    gap:13px;
    
}

.popupActions{
    
    > div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:13px;
    }
}


.popupRessource{
    border-top: solid 1px var(--text-color);
    border-bottom: solid 1px var(--text-color);
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:15px;
    @include max-responsive(sm) {
        gap:15px;
    }

    p{ 
        text-decoration: none;
        display:flex;
        color:var(--text-color);
        font-family: $font-montserrat;
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        @include max-responsive(sm) {
            font-size: 12px;
        }

    }

    a{
        text-decoration: none;
        display:flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color:var(--text-color);
        font-family: $font-montserrat;
        font-size: 16px;
        margin-left: auto; /* Cela pousse cet élément et tout ce qui suit vers la droite */

    }
    button{
        all:unset;
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
    }

}

.popupCg{
    display:block;
    font-size: 10px;
    text-align:left;
    overflow: hidden;
    height: 0px;
    transition: height 1s ease-out;
    font-style: italic;

}

.showCG{
    display: none;
}

.showCGLabel{
    display: inherit;
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    text-decoration: underline;
}

.showCG:checked + .showCGLabel + .popupCg{
    height: 100%;
}

.endCta {
 background-color: var(--text-color);
 padding: 15px;
 border-radius: 8px;
 color: white;
 font-size: 18px;
 border: none;
 text-decoration: none;
 font-weight: 600;
 transition: transform 1s $ease-out-expo;
 align-items: start;
    &:hover{
        transform: scale(1.1);
    } 

}

.scenarioImageEnd {
    position: relative;
    padding-bottom: 61%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 61%;

    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.popupInfoFinish {
    visibility: hidden;
    opacity: 0;
    z-index: 161535744;
    position: fixed;
    top: 50%;
    right: var(--page-gutter);
    background-color: #DCF9F5;
    padding: 30px 32px 40px;
    width: 532px;
    max-width: 100%;
    color:var(--text-color);
    @include max-responsive(sm){
        background-color: #DCF9F5;
        backdrop-filter: blur(5px);
        top: inherit;
        bottom: 0;
        left: 0;
        right: inherit;
        width: 100%;
        transform: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        z-index: 161535741;
    }
}

.popupInfoCompleted {
    visibility: hidden;
    opacity: 0;
    z-index: 161535744;
    position: fixed;
    top: 50%;
    right: var(--page-gutter);
    background-color: #ffffff;
    padding: 30px 32px 40px;
    width: 532px;
    max-width: 100%;
    color:var(--text-color);
    @include max-responsive(sm){
        background-color: #DCF9F5;
        backdrop-filter: blur(5px);
        top: inherit;
        bottom: 0;
        left: 0;
        right: inherit;
        width: 100%;
        transform: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        z-index: 161535741;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Couleur noire avec 50% d'opacité */
    z-index: 161535740; /* Assurez-vous que cela soit inférieur à celui de votre popup */
}


.separationLine {
    border-top: 0.5px solid var(--text-color); /* Vous pouvez personnaliser la couleur et l'épaisseur de la ligne */
    margin-top: 10px; /* Espacement au-dessus de la ligne (personnalisable) */
    margin-bottom: 20px; /* Espacement en dessous de la ligne (personnalisable) */
}