@import "./shared.scss";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&family=Nanum+Pen+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

.button {
    border: 0.5px solid var(--text-color);
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-color: white;
    color: var(--text-color);
    padding: 0 20px;
    gap: 20px;
    text-align: left;
    font-family: $font-montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    transition: transform 1s $ease-out-expo;

    &:hover {
        transform: scale(1.1);
    }

    @include max-responsive(sm) {
        font-size: 14px;
    }

    span {
        flex-grow: 0;
    }

    &.side--left {
        flex-direction: row-reverse;
        justify-content: flex-start;
    }

    &.side--right {
        padding-left: 20px;
        justify-content: flex-end;

        svg {
            transform: rotate(180deg);
        }
    }

    &.button--primary {
        background-color: var(--text-color);
        color: white;
    }

    svg {
        width: 20px;
    }
}


.t-nanum {
    font-family: $font-montserrat;
    font-size: 20px;
    font-weight: 600;

    @include max-responsive(sm) {
        text-shadow: none;
        font-size: 14px;

    }
}


h2 {
    font-size: 45px;
    line-height: 0;
}