@import "../styles/shared.scss";

.logo {
    position: fixed;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 161342000;
    top: 3%;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }


  .logoScenarioActive {

    position: fixed;
    left: calc(var(--page-gutter) + 35px);
    z-index: 161342000;
    top: 2%;
    justify-content: start;
    align-items: center;
    background-color: transparent;
    display: none;
}
  @include max-responsive(sm){
    .logo{
        top:  calc(var(--page-gutter) - 15px);
      position: fixed;
      z-index: 161342000;
      left: 50%;
      transform: translate(-50%,0);
      svg {
        width: 40px;
    }
    }

    .logoScenarioActive {
        position: fixed;
        z-index: 161535740;
        top:  calc(var(--page-gutter) - 15px);
        left: var(--page-gutter) ;
        justify-content: start;
        align-items: center;
        background-color: transparent;
        width: 1px ;

        svg {
            width: 40px;
        }

      
      }
  }
  
  .MentionsLegales{
    position: fixed;
    z-index: 1615357480000000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #DCF9F5;
    display: flex;
    flex-direction: column;
    border: var(--text-color) 8px solid;
    color: var(--text-color);
    padding: 100px;
    text-align: left;
    overflow: scroll;

    @include max-responsive(sm) {
        background-size: 100%;
        padding: 20px;
        
    }
}

.closeButton {
  position: absolute;
  top: 50px; /* Ajustez selon la mise en page */
  right: 150px; /* Ajustez selon la mise en page */
  cursor: pointer;
  background-color: #DCF9F5;
  border-radius: 4px;
  border: 1px solid var(--text-color);
  color: var(--text-color);
  padding: 10px;
  font-weight: 700;
  @include max-responsive(sm) {
    top: 20px; /* Ajustez selon la mise en page */
    right: 15px; /* Ajustez selon la mise en page */

}
}
