@import "../../styles/shared.scss";

.tuto{
  position: fixed;
  z-index: 1615357490; /* Assurez-vous que cela soit inférieur à celui de votre popup */
  opacity: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.5); /* Couleur noire avec 50% d'opacité */

}
.tutobox {
  position: fixed;
  left: var(--page-gutter);
  top: var(--page-gutter);
  border-radius: 20px;
  background-color: var(--brand-color);
  width: 345px;
  padding: 35px 40px;
  color: var(--text-color);
  text-align: left;
  pointer-events: auto;

  @include max-responsive(sm){
    top: 50%;
    transform: translate(0%,-50%);
    width: 90%; // Ajustez selon les besoins
    padding: 20px; // Ajustez selon les besoins
    }



  h3{
    font-family: $font-montserrat;
    font-weight: 900;
    line-height: 1.1;
    font-size: 20px;
    margin-bottom: 10px !important;    z-index:2 ;

  }
  > .content{
    z-index:2 ;
    display: flex;
    align-items: center;


    @include max-responsive(sm){
      width: 100%;
    }
    p{
      font-family: $font-montserrat;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.2;
      margin-bottom: 15px !important;
      z-index:2 ;

    
    }
    svg{
      display: flex;
      margin-left: 15px;
      width: 35%;
      height: 35%;
      justify-content: center;
      align-items: flex-end;

    }
  }

  .arrowLeft, .arrowRight {
    z-index:2 ;
    color: var(--brand-color);
    background: var(--text-color);
    border-radius: 100px;
    font-size: 15px;
    font-weight: 700;
    width: 20px;
    height: 20px;
    text-align: center;
    

    cursor: pointer;
    @include max-responsive(sm){
  }
    // Stylisez comme vous le souhaitez
  }

  .arrowLeft {
    align-self: flex-start;
    
  }

  .arrowRight {
    align-self: flex-end;


  }
}

.radioInputs{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  input {
    margin-right:.75em;
    appearance: none;
    background-color: #fff;
    font: inherit;
    width: .9em;
    height: .9em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    z-index: 3;

    transition: background-color .25s;
    &:checked{
      background: var(--text-color);

    }
  }
}

.popupClose{
  all:unset;
  position: absolute;
  right: 35px;
  top: 35px;
 
  z-index: 3;
  cursor: pointer;
  transform: translate(25%, -50%) ;
  transition: transform 1s $ease-out-expo;
  &:hover{
    transform: translate(25%, -50%) scale(1.1);
  }
  @include max-responsive(sm){
    right: 27px;
    top: 35px;
  }
}


.overlay2 {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Couleur noire avec 50% d'opacité */
    z-index: -10; /* Assurez-vous que cela soit inférieur à celui de votre popup */

}
