@import "./styles/shared";


.transition {
    position: fixed;
    z-index: 16153574800;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #DCF9F5;
    background-image: url("../public/fondtransition.png");
    background-repeat: no-repeat;
    background-size: 42%;
    background-position: 0% 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    border: var(--text-color) 8px solid;

    @include max-responsive(sm) {
        background-size: 100%;
        background-image: none;

    }

    svg {
        width: 240px;
        margin-bottom: 10px;

        @include max-responsive(sm) {
            width: 140px;
            margin-bottom: 0px;

        }
    }
}

.content {
    position: relative;
    max-width: 600px;
    color: #333437;

    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;

    @include max-responsive(sm) {
        font-size: 16px;
        max-width: 80%;
        line-height: 25px;

    }

    h2 {
        color: var(--text-color);

        @include max-responsive(sm) {
            font-size: 30px;


        }

    }

    h3 {
        color: var(--text-color);
        font-size: 28px;
        line-height: 38px;


        @include max-responsive(sm) {
            font-size: 16px;


        }

    }

    b {
        font-weight: 800;
        color: #373940;


    }
}

.start {
    position: relative;
    margin-top: 45px;
    opacity: 0;
    font-family: $font-montserrat;
    color: #fff;
    background-color: transparent;
    border-radius: 4px;
    padding: 12px 20px;
    height: 48px;
    font-size: 1em;
    font-weight: 600;
    border: 1px solid var(--text-color);
    background-color: #0A2D82;
    pointer-events: none;

    @include max-responsive(sm) {
        font-size: 0.8em;
        margin-top: 25px;

    }


    &:hover {
        background-color: #05194b;

    }
}



.mentions {
    position: absolute;
    bottom: 5%;

    a {
        color: #0A2D82;
        font-size: 14px;

    }

    span {
        color: #0A2D82;
        text-decoration: underline;
        font-size: 14px;

        cursor: pointer;
    }
}

.nonaccess {
    position: fixed;
    top: 3.5%;
    left: 50%;
    transform: translate(-50%, 0);
    color: #0A2D82;


    a {
        text-decoration: none;
    }

    @include max-responsive(sm) {
        font-size: 14px;
        width: 100%;
    }
}

.nonaccess a .underline {
    text-decoration: underline;
}

.nonaccess a,
.nonaccess a:link,
.nonaccess a:visited,
.nonaccess a:hover,
.nonaccess a:active {
    color: #0A2D82;
    /* Ou toute autre couleur que vous souhaitez utiliser */
    text-decoration: none;
    font-size: 14px;

}