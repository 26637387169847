@import "./shared.scss";

:root{
  --page-gutter: 30px;
  --brand-color: #D3D62E;
  --text-color:#0A2D82;
  --border-radius: 40px;

  @include max-responsive(sm){
      --page-gutter: 15px;
  };
}

*{
  box-sizing: border-box;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  -webkit-tap-highlight-color: transparent;

  &::-webkit-scrollbar {
    display: none;
  }
}

