.App {
  text-align: center;
  height: 100svh;
  overflow: hidden;

}


.logo-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 161535742;
  top: 3%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100px; /* ou la hauteur que vous souhaitez */

}
@media (max-width:820px){
  .logo-container{
    top: var(--page-gutter);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo-container {
  position: fixed;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 161535742000;
  top: 3%;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100px; /* ou la hauteur que vous souhaitez */

}
@media (max-width:820px){
  .logo-container{
    top: var(--page-gutter);
  }
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#onetrust-consent-sdk {
  position: absolute;
  z-index: 1000000000000000;

}