@import "../../styles/shared.scss";




.iconBoxIcon{
    visibility: hidden;
    opacity: 0;
    position: fixed;
    z-index: 20;
    left: 50%;
    bottom: 10px;
    text-align: center;
    transform: translate(-50%,0);
    display: flex;
    align-items: center;
    gap:14px;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding-top: 10px ;
    padding-bottom: 10px ;
    padding-left: 25px;
    padding-right: 25px;
    background-color: var(--brand-color);
    max-width: 400px;

    @include max-responsive(sm){
        display: flex;
        background-color: var(--brand-color);
        width: 70%;
        justify-content: center;
        bottom: 18px;


    }
    span{
        color:#0A2D82;
        font-size: 14px;
        font-weight: 600;
        @include max-responsive(sm){
            font-size: 11px;

        }
    }

    svg{
        color: #fff;
        &:nth-of-type(1){
            @include max-responsive(sm){
                display: none;
            }
        }
        &:nth-of-type(2){
            display: none;
            @include max-responsive(sm){
                display: block;
            }
        }
    }
}

