@import "../../styles/shared.scss";

.ressource{
    position: fixed;
    z-index: 161535740;
    top: calc(var(--page-gutter) + -6px);
    right: calc(var(--page-gutter) + 35px);
    @include max-responsive(sm){
        top:  calc(var(--page-gutter) - 8px);;
        right: calc(var(--page-gutter) + 42px);
    }
}

.ressourceButton{
    transition: transform .8s cubic-bezier(.4,1.73,.74,1.18);
    cursor: pointer;
    background: none;
    border: none;
    &:hover{
        transform: scale(1.15);
        @include max-responsive(sm){
            transform: none;
        }
        .counter{
            transform: scale(1.15) translateX(-50%);
        }
    }
    .counter{
        position: absolute;
        bottom: 0;
        left: 15%;
        transform: translateX(-50%);
        min-height: 1.5em;
        min-width: 1.5em;
        transition: transform .8s cubic-bezier(.4,1.73,.74,1.18);
        @include max-responsive(sm){
            min-height: 1.2em;
            min-width: 1.2em;   
            bottom: 10%;
            left: 18%;     }


        p{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background: #ffffff;
            padding: 0.05em;
            margin: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            color:var(--text-color);
            font-family: $font-montserrat;
        }
    }

    svg {
        width: 38px;
        @include max-responsive(sm){
            width: 35px;
        }
    }
}

.ressourceContent{
    position: absolute;
    right: 0;
    top: calc(100% + 15px);
    background-color: var(--brand-color);
    border-radius: var(--border-radius);
    padding: 15px 20px 20px;
    width: 564px;
    max-height: 800px;
    text-align: left;
    opacity: 0;
    overflow: scroll;
    @include max-responsive(sm){
        top: calc(100% + 25px);
        width: calc(100vw - (var(--page-gutter) * 2));
        max-height: 50vh;
    }
    p{
        font-family: $font-montserrat;
        font-weight: 900;
        line-height: 1.2;
        font-size: 16px;
        color:var(--text-color);
    }
    ul{
        list-style: none;
        padding-left: 0;
        li{
            padding: 10px 0;
            border-top: solid 1px var(--text-color);
            a{
                text-decoration: none;
                color:var(--text-color);
                font-family: $font-montserrat;
                font-size: 19px;
                display:flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.copyBtn{
    background: transparent;
    border: none;
    font-family: $font-montserrat, cursive;
    color: var(--text-color);
    text-decoration: underline;
    padding :0;
    font-size: 16px;
}

.modalCopy{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    background: var(--brand-color);
    color: var(--text-color);
    padding: 0.5em 1em;
    border-radius: 25px;
    font-family: "Architects Daughter", cursive;
    font-weight: bold;
    border: 2px solid var(--text-color);
    opacity: 0;

}